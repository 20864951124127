.help {
  opacity: 1;
  position: absolute;
  background: white;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  padding: 5px 5px;
  left: 0;
}
.help h2 {
  margin: 5px;
}
.help img {
  width: 220px;
}
.help .boton {
  margin-top: 5px;
}

.help-icon {
  text-align: center;
  cursor: pointer;
}
.help-icon i {
  position: relative;
  top: -4px;
  font-size: 1.15em;
  color: #1890ff;
}


