section.memoset {
  margin-top: 1em;
}
section.memoset .couple-list {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
section.memoset .couple-list div {
  margin: 5px;
  min-width: 130px;
  min-height: 130px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  border: 1px solid #d4d4d4;
}
section.memoset .couple-list div > img {
  margin: 2px;
  width: 100%;
}
section.memoset .couple-list div > img.bead {
  max-height: 115px;
  width: auto;
}
section.memoset .couple-list div span {
  font-size: 3em;
}
section.memoset .couple-list.completed {
  max-height: 50vh;
  overflow: hidden;
}

.botones.memoset {
  margin-top: 10vh !important;
  position: static !important;
}

.exercise-body.memo-body {
  height: auto !important;
}


