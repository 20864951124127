section.coupleschall {
  margin: 1em 0;
}
section.coupleschall .couple-list {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
section.coupleschall .couple-list div {
  margin: 5px;
  min-width: 130px;
  min-height: 130px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  border: 1px solid #d4d4d4;
}
section.coupleschall .couple-list div > img {
  margin: 2px;
  width: 100%;
}
section.coupleschall .couple-list div > img.bead {
  width: 30px;
}
section.coupleschall .couple-list.completed {
  max-height: 50vh;
  overflow: hidden;
}
section.coupleschall .clock img {
  max-width: 50px;
}

.botones.coupleschall {
  margin-top: 10vh !important;
  position: static !important;
}

.exercise-body.couple-body {
  height: auto !important;
}


