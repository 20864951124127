.whatnumberis {
  position: relative;
  top: 0vh;


  .abacus-cover{
    scale: 0.5;
    -webkit-transform: scale(1);
  }

  .the-number {
    margin-top: 0 !important;
  }

  .input-number .input {
    margin-top: 5vh !important;
    border: 2px solid #e7e7e7 !important;
    border-radius: 10px;
    padding: 20px !important;
    font-size: 1.2em !important;
    text-align: center;
  }

}