section.quantitygame {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;

  .abacus {
    min-width: 60px;
    margin: auto 0.5em;
  }

  .dragobjects {
    width: auto;
    display: inline-block;
    margin: auto;
    margin-left: 20px;

    .drag_things_to_boxes {
      .box {
        border: 3px solid #e7e7e7;
        border-radius: 1em;
        height: 180px;
        width: 230px;
        margin: 10px 0;
        padding: 5px;
        position: relative;
        padding-top: 25px;


        .box_item_component {
          width: auto;
          display: inline-block;
        }

        .box_item_component .outer {
          border-top: none;
          min-width: 40px;
          max-width: 100px;
          overflow: hidden;
        }

        .box_item_component .item {
          color: white;
          margin: 3px;
        }

        .box_item_component .ddcontainerghost {
          border: 3px dashed #e7e7e7;
          border-radius: 60%;
          background-color: #8ae95b;
          text-align: center;
        }

        .box_item_component .ddcontainerghost .outer {
          margin: auto;
        }

        .box_item_component img {
          width: 100%;
          height: 100%;
        }
      }

      .boxes.coins .box {
        height: 190px;
      }

      .highlighted .box {
        box-shadow: inset 0 0 4px #1890ff;
      }

      .inter-boxes {
        position: absolute;
        z-index: 100;
        margin-top: -30px;
        margin-left: 20%;
        height: 60px;


        img {
          height: 100%;
        }
      }

    }
  }

}