@import '../../../../_colors.less';

@op-radius: 20;

.completeoperations {
    .transparent {
        color: @transparent;
    }

    .ddcontainerghost {
        border: 3px dashed @neutralback;
        border-radius: 60%;
        background-color: #8ae95b;
        text-align: center;
    }

    .number {
        .the-number {
            font-size: 2em !important;

            .equation {
                display: flex;
                height: @op-radius*2.5px;
                justify-content: center;

                .operation {
                    display: inline-block;
                    margin: 0 5px;
                    position: relative;
                    background-color: @lightneutralback;
                    border: 1px solid @challenge_color;
                    width: @op-radius*2.5px;
                    height: @op-radius*2.5px;
                    border-radius: @op-radius*1.25px;

                    .component_box {
                        display: inline-block;
                        width: 100%;
                        text-align: center;

                        .droptarget {
                            display: inline-block;
                            width: 100%;
                        }


                        .box {
                            width: @op-radius*3px;
                            height: @op-radius*3px;
                            line-height: @op-radius*2.5px;
                            // border: 1px solid yellow;
                            position: relative;
                            left: -6px;
                            top: -1px;

                            img {
                                width: @op-radius*2px;
                                position: relative;
                                top: -3px;
                            }
                        }

                    }
                }

                .num {
                    display: inline-block;
                    height: @op-radius*2.5px;
                    line-height: @op-radius*2.5px;
                }
            }

            .equals {
                font-size: 1.5em;
            }

            .resultn {
                font-size: 1.3em;
                color: @challenge_color;
            }
        }

        margin: 24px 0;
        font-weight: bolder;
    }

    .inter-boxes {
        position: absolute;
        z-index: 100;
        margin-top: -5px;
        margin-left: 35%;
        height: 60px;

        img {
            height: 100%;
        }
    }

    .bag {
        margin: 35px auto 15px;
        text-align: center;
        display: inline-block;
        width: 80vw;
        background-color: @lightneutralback;
        border-radius: 10px;
        border: 1px solid @base-color;

        .component_box {
            display: inline-block;
            width: 100%;

            .droptarget {
                display: inline-block;
                width: 100%;
            }

            .box {
                height: @op-radius*4px;
                line-height: @op-radius*4px;
                width: 100%;

                // border: 1px solid @challenge_color;
                .box_item_component {
                    display: inline;
                    margin: 5px;
                    max-width: 95px;
                    max-height: 95px;

                    img {
                        width: @op-radius*2px;
                    }
                }
            }
        }


    }

    .ddcontainerghost {
        border: 1px solid @validback;
        border-radius: @op-radius*1px;
        width: @op-radius*2px;
        height: @op-radius*2px;
        line-height: @op-radius*2px;
        background-color: white;
        text-align: center;
    }

    .ddcontainerghost .outer {
        margin: auto;
    }
}