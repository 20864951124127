@import "../../_colors.less";
@import "../../_dims.less";

.profile {
  
  .profile-body,
  .login-body {
    margin: 0 auto;
    padding-bottom: 100px;
  }

  form {
    padding: 0 1em 0 1em;
    text-align: left;

    .ant-upload-picture-card-wrapper {
      display: flex;
      justify-content: center;
      padding-left: 1em;
    }
  }

  .topLine {
    padding-left: 2vh;
    padding-right: 2vh;
    height: 7vh;
    padding-bottom: 1em;
    margin-top: 2vh;
    margin-bottom: 3vh;
    border-bottom: 0.25px solid @border-color;

    img {
      z-index: 1;
    }

    .title {
      // position: absolute;
      justify-self: center;
      width: 100%;
      z-index: 0;
      text-align: center;
      left: 0;
      font-weight: 900;
    }

    .edit {
      z-index: 1;
      color: @primary-color;
    }

    .centerer {
      margin-left: 0;
    }
  }

  .profile-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 100px 0;

    .profile-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1em;

      .gamer-avatar {
        position: absolute;
        margin-top: -2.8em;
        align-self: center;
        height: 5.5em;
        width: 5.5em;
        border-radius: 15px;
        border: 1px solid @primary-color;
        background-color: @primary-color;

        img {
          height: 101%;
          width: 101%;
        }
      }

      .my-data-data {
        text-align: center;
        border-top: none;
        padding: 0;

        .gamer-nick {
          /* margin-top: 2.7em; */
          font-size: 1.5em;
          margin-bottom: 0;
        }
      }
    }

    .subscription {
      margin-bottom: 1em;
      margin-top: -2em;
    }

    .my-data {
      background-color: white;
      max-width: @MAX-WIDTH;
      width: 80%;
      border-radius: 10px;
      border: 1px @border-color solid;
      margin: 0 auto;
      margin-bottom: 25px;
      

      .my-data-header {
        padding: 0.4em 1em 0.4em 1em;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        h3 {
          margin-bottom: 0;
        }
      }

      .my-data-data {
        text-align: left;
        border-top: 1px @border-color solid;
        padding: 1em;
        display: flex;
        margin-bottom: 0;
        flex-direction: row;
        align-items: flex-start;
        color: @font-color;
        word-break: break-word;

        h4 {
          margin-bottom: 0;
          max-width: 84px;
        }

        .bold {
          font-weight: 700;
        }

        img {
          margin-left: 0.3em;
        }


      }

      img.age_img {
        width: 32px;
        top: -5px;
        position: relative;
        margin: 0 0.5em;
      }

      .my-data-data:first-child {
        padding: 1em;
        border-top: none;
      }

      .ant-list-item {
        border-bottom: none;
      }

      .edit-name {
        border: 0;

        .ant-collapse-item {
          background-color: transparent;
          border: 0;

          .ant-collapse-header {
            padding: 0;

            .ant-collapse-expand-icon {
              margin-top: 7px;
            }
          }

          .name-form {
            display: flex;
            input {
              margin: 0 !important;
              padding: 0;
              font-size: 1em !important;
            }
            button{
              background-color: @primary-color;
              border: 0;
              color: white;
              border-radius: 50%;
              line-height: 1em;
              height: 32px;
              width: 32px;

              &:disabled{
                background-color: @base-color;
              }
            }
          }

        }
      }

      .select-age {
        border: 0;

        .ant-collapse-item {
          background-color: transparent;
          border: 0;

          .ant-collapse-header {
            padding: 0 8px;
          }

          .age-button img {
            margin: 0;
          }
        }
      }
    }

    .lang-list {
      .lang-button {
        border-top: 1px solid @border-color;

        .ant-avatar {
          margin: 0 1em;
        }

        h4 {
          margin-bottom: 0;
          margin-right: 2em;
        }
      }
    }

    .age-list {
      .age-button {
        border-top: 1px solid @border-color;

        .ant-avatar {
          margin: 0 1em;
        }

        h4 {
          margin-bottom: 0;
          margin-right: 2em;
        }
      }
    }

    .lang-select {
      width: 100%;

      .edit-lang {
        margin-top: 0;
      }
    }

    .profile-buttons {
      margin: 5vh 0 0 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      border-bottom: 1px solid @border-color;

      a {
        width: 80%;
      }

      .button-next {
        color: white;
        width: 100%;
      }

      .button-danger {
        color: @error;
        width: 100%;
      }
    }

    .button-container {
      margin: 0 2vh;
    }

    .logout {
      max-width: @MAX-WIDTH;
      width: 80%;
      margin: 0 auto;

      .button-next {
        background-color: transparent;
        color: @active2;
      }
    }

    .subscribe-button{
      width: 100%;
    }

  }

  form {
    .ant-upload-picture-card-wrapper {
      width: auto;
    }

    .ant-upload {
      border: 0;
      border: none;
      padding-right: 0;
      background: none;

      .ant-upload.ant-upload-select-picture-card {
        margin-right: 0;
      }

      .image {
        margin-right: 0;
        padding: 0;

        img {
          width: 82px !important;
          height: 81px !important;
          border: 2px solid @primary-color;
          background-color: @primary-color;
          border-radius: 15px;
          align-self: center;
        }
      }

      h4 {
        color: @primary-color;
      }
    }

    h3 {
      margin-top: 1.5em;
      font-weight: 600;
    }

    input {
      padding: 1.5em 1em 1.5em 1em;
      border-radius: 7px;
      font-weight: 600;
    }

    .ant-select-selection {
      height: 3em;
      border-radius: 7px;

      .ant-select-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 600;
      }
    }

    .ant-calendar-picker {
      width: 100%;
    }

    .next {
      margin-top: 2em;
      padding-bottom: 12vh;
    }

    .input-feedback {
      height: 15px;
      width: 15px;
    }
  }

  .edit-profile {
    padding-bottom: 12vh;

    .edit-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 3em;
    }
  }

  .ant-tabs-tab-arrow-show {
    display: none;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    justify-content: center;
    font-weight: bold;
    font-size: 0.85em;
    padding-left: 0;
    padding-right: 0;
    background-color: @background-color;
    border-top: solid rgb(232, 232, 232) 1.5px;
    border-bottom: solid rgb(232, 232, 232) 1.5px;
  }
}

.apple-profile {
  padding-top: 5vh !important;
}

.login-body {
  .login-form {
    padding-top: 2vh;

    .login-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 2vh;
      margin-right: 2vh;
      justify-content: center;
    }

    .login-form-inputs {
      padding-top: 10vh;
      margin: 0 4vh 2vh 4vh;

      h3 {
        margin: 0;
        padding: 0;
      }

      .login-titles {
        text-align: left;
      }

      .ant-input-password {
        border: none;
        padding: 0;
        font-size: 1em;
        background-color: transparent;
      }

      input {
        margin: 0 !important;
      }

      .first-input {
        margin-bottom: 3em !important;
      }

      .forgotten-pw {
        color: black;
        text-align: right;
        margin-top: 1vh;
        font-size: 1em !important;
      }

      .button-container {
        margin-right: 0;
        margin-left: 0;
        margin-top: 6vh;
      }

      .button-next {
        margin-top: 8vh;
        margin-bottom: 3vh;
      }
    }

    .login-with {
      .facebook-google {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: center;
        margin: 1vh 0em 0 0em;

        .facebook {
          border: 1px solid;
          margin: 1em;
          padding: 0.75em;
          width: 40px;
          align-content: center;
          justify-content: center;
          display: flex;
          height: 40px;
          object-fit: contain;
          border-radius: 100px;
          border-color: #4267b2;

          img {
            width: 40px;
          }
        }

        .google-logo {
          border: 1px solid;
          margin: 1em;
          padding: 0.75em;
          width: 40px;
          align-content: center;
          justify-content: center;
          display: flex;
          height: 40px;
          object-fit: contain;
          border-radius: 100px;
          border-color: #de5246;
        }

        .apple {
          border: 1px solid;
          margin: 1em;
          padding: 0.75em;
          width: 40px;
          align-content: center;
          justify-content: center;
          display: flex;
          height: 40px;
          object-fit: contain;
          border-radius: 100px;
          border-color: black;

          img {
            margin-top: -1px;
            width: 40px;
          }
        }
      }
    }
  }

  .create-profile {
    color: @active2;
    font-weight: 600;
  }

  .react-datepicker__input-container {
    width: 100%;

    input {
      padding: 1.1em 1em 1em 1em;
      border-radius: 7px;
      font-weight: 600;
      width: 100%;
      vertical-align: middle;
      border: 1px solid #d9d9d9;
    }
  }
}

.remember {
  max-width: @MAX-WIDTH;
  margin: 0 auto;

  background: white;
  padding: 5vh;

  .login-title {
    display: flex;
    flex-direction: row;
    padding: 10vh 2vh;
    justify-content: center;
  }

  .remember-container {
    text-align: left;
  }

  .button-container {
    padding: 5vh 0 0;
  }
}