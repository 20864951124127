@import "../../../_colors.less";

.pathgrid {
    display: table;
    text-align: center;
    margin: 0 auto;
    position: relative;

    .pathgrid-row {
        display: table-row;

        .pathgrid-item {
            border: 1px solid #ccc;
            padding: 5px;
            margin: 5px;
            display: table-cell;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border-radius: 10px;

            >div {
                display: inline-block;

                .pabacus {
                    width: 18px;
                }
            }

            &.showpath {
                background-color: @challenge_color;
            }

            &.selected {
                background-color: @practice_color;
            }

            &.error {
                background-color: @error;
            }
        }

    }
}

@cell-size: 60px;

.pathgrid.forward {
    .pathgrid-row {

        height: @cell-size;
        .pathgrid-item {
            min-width: @cell-size;
            line-height: @cell-size;
            &.selected{
                background-color: @active3;
                color: white;
            }

            &.start, &.end{
                background-color: @challenge_color;
                img{max-width: 32px;}
                font-weight: 900;
                font-size: 1.5em;
            }
        }
    }
}