.social-share {
    max-width: 100%;

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: center;

        li {
            margin: 4px;
        }
    }
}