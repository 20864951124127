section.baloonschall {
  width: 100%;
}
section.baloonschall .baloon-list {
  min-height: 65vh;
  border: 1px solid #e7e7e7;
  position: relative;
  margin-bottom: 2px;
}
section.baloonschall .baloon-list .baloon {
  position: absolute;
  height: 130px;
  width: 80px;
  display: inline-block;
}
section.baloonschall .baloon-list img {
  max-height: 70px;
}

.botones.balloons {
  margin-top: 30vh !important;
  position: static !important;
}

.exercise-body.baloons-body {
  height: auto !important;
}


