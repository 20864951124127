.baloon_back {
    background-size: contain !important;
    padding: 10px 0 0 0;
}

.baloon_back.b1 {
    background: url('imgs/baloon_back_1.png') no-repeat center 0;
}

.baloon_back.b2 {
    background: url('imgs/baloon_back_2.png') no-repeat center 0;
}

.baloon_back.b3 {
    background: url('imgs/baloon_back_3.png') no-repeat center 0;
}

.baloon_back.b4 {
    background: url('imgs/baloon_back_4.png') no-repeat center 0;
}

.baloon_back.b5 {
    background: url('imgs/baloon_back_5.png') no-repeat center 0;
}

.baloon_back.b6 {
    background: url('imgs/baloon_back_6.png') no-repeat center 0;
}

.baloon_back.b7 {
    background: url('imgs/baloon_back_7.png') no-repeat center 0;
}

.baloon_back.b8 {
    background: url('imgs/baloon_back_8.png') no-repeat center 0;
}

.baloon_back.b9 {
    background: url('imgs/baloon_back_9.png') no-repeat center 0;
}

.baloon_back.b10 {
    background: url('imgs/baloon_back_10.png') no-repeat center 0;
}

.baloon_back.b11 {
    background: url('imgs/baloon_back_11.png') no-repeat center 0;
}

.baloon_back.b12 {
    background: url('imgs/baloon_back_12.png') no-repeat center 0;
}

.baloon_back.b13 {
    background: url('imgs/baloon_back_13.png') no-repeat center 0;
}

.baloon_back.b14 {
    background: url('imgs/baloon_back_14.png') no-repeat center 0;
}

.baloon_back.b15 {
    background: url('imgs/baloon_back_15.png') no-repeat center 0;
}