@import "../../../_colors.less";


.avatar-picker {
    margin: 10px;
    display: inline-block;

    @user-avatar-size: 80;

    .avatar {
        width: @user-avatar-size*1px;
        height: @user-avatar-size*1px;
        // background-color: @avatar-back;
        border-radius: (@user-avatar-size/2)*1px;
        margin: 8px;
        display: inline-block;
        background-image: url();
        background: url('./imgs/origami.png') center no-repeat @avatar-back;
        background-size: 40px 40px;

        img {
            width: 100%;
            border-radius: (@user-avatar-size/2)*1px;
        }
    }
}

.avatar-picker-popover {
    width: 90vw;

    .ant-popover-arrow .ant-popover-arrow-content::before {
        background-color: @avatar-back;
    }

    .ant-popover-inner {
        background-color: @avatar-back;
        text-align: center;

        .ant-popover-title {
            border: none;
            font-size: 1.5em;
            font-weight: 700;
            padding: 16px 0 8px;

        }

        .ant-popover-inner-content {
            text-align: center;
            width: 100%;
        }

        .avatar-list {
            @user-avatar-size: 72;

            .avatar {
                width: @user-avatar-size*1px;
                height: @user-avatar-size*1px;
                background-color: @avatar-back;
                border-radius: (@user-avatar-size/2)*1px;
                margin: 8px;
                display: inline-block;

                img {
                    width: 100%;
                    border-radius: (@user-avatar-size/2)*1px;
                }
            }
        }

    }
}