@import "../../_colors.less";
@import "../../_dims.less";

.freemode{
    margin: 0 auto;
    text-align: center;
    height: 90vh;
    position: relative;
    /* .abacus{
        top: 50%;
        margin-top: -128px;
    } */

    .freemode-cover{
        position: relative;
        top: 50%;
        margin-top: -180px;
    }

    .botones{
        /* justify-content: right;
        padding-right: 2em;
        margin-top: -15px;
        position: relative;
        z-index: 1000; */

        /* button{
            background-color: @background-color;
            color: @primary-color;
            border-color: @primary-color;
            padding: 0 1em;
            margin: 0 0.5em;
        } */
    }

    .tolandscape{
        width: 110vw;
        height: 90vh;
        margin-top: 160px;
    }

}