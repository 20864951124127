@import "../../_colors.less";

.dailygoal {}

.dailygoal-modal {
    .ant-modal-body {
        text-align: center;
        font-size: 1.2em;
        font-weight: 700;
        color: @base-color;



        img {
            max-width: 100%;
            max-height: 40vh;
        }

        .points {
            img {
                height: 32px;
            }
        }

        .circular-progress {
            width: 50%;
            margin: 0 auto;
        }
    }
}

.ant-radio-wrapper {
    span {
        display: block;
    }

    .ant-radio-inner {
        color: @active2;
        border-color: @active2;
    }
}