section.sumsubstractionseries {
  .selection {
    display: flex;
    margin-top: 30vh;
    flex-flow: wrap;
    width: 100%;
    justify-content: center;

    button {
      margin: 0.5em;
      font-size: 2em;
      width: 60px;
      height: 60px;
      padding: 0;
    }

    button:disabled {
      background-color: #ffd13a;
      color: white;
      border-color: #ffd13a;
    }
  }
}