@import "../../../_colors.less";

.levels-header-logo {
    width: 100vw;
    margin: 0 0;
    border-bottom: 3px solid @lines;
    background: @mis_background;
    z-index: 2;
    position: fixed;
    top: 0;
    padding-bottom: 6px;
    padding-top: 25px;
    left: 0;

    .inner-logo {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        max-width: 720px;
        position: relative;

        img.logo {
            height: 40px;
            margin-right: 16px;

            @media only screen and (max-width: 360px) {
                margin-right: 52px;
            }
        }

        .levels-header-points-time {
            position: absolute;
            right: 16px;
            line-height: 32px;
            margin-top: 8px;

            .time {
                font-weight: 900 !important;
                margin-right: 8px;
                font-size: 17px;
            }

            .points {
                font-weight: 900 !important;
                color: inherit;

                img {
                    height: 32px !important;
                    margin-right: -4px;
                    margin-top: -6px;
                }
            }
        }
    }
}

.levels-header-title {
    text-align: center;
    padding: 92px 0 15px;
    background: @mis_background;

    h1 {
        font-weight: 900;
        font-size: 1.5em;
        margin: 0;
        padding: 0;
    }
}