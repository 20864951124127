@import "../../../_colors.less";

section.mentalabacusaixation {
  width: 100%;

  .clock {
    .blank {
      border: 1px solid #e7e7e7;
      height: 40vh;
      margin: 1em;
      color: #b8c1cb;
      font-size: 1.5em;
      justify-content: center;
      align-items: center;
      align-items: center;
      display: flex;
      flex-flow: column;
    }

    img {
      max-width: 50px;
    }
  }

  .maf_message{
    img {
      max-width: 50px;
    }
  }
}