@import '../../../../_colors.less';

.memomesh {
  background-color: transparent;

  .memomesh-section{
    .indicators{
      img{
        max-width: 50px;
      }
    }
  }
}