@import "../../_colors.less";
@import "../../_dims.less";

.resources {
    .levels-header-title {
        padding-bottom: 0;
    }

    .header-unit-body {
        span.header-unit-body-desc {
            color: @practice-levels;
        }
    }

    .level-separator {
        span {
            min-width: 15% !important;
        }

        span:nth-child(2) {
            background: transparent !important;
            // color: @font-color;
            color: @secondary-color;
            width: 70% !important;
        }
    }

    .level {
        .ant-collapse-header {
            cursor: pointer !important;

            .header-unit .header-unit-body {

                .header-unit-body-title {
                    color: @font-color;
                }

                .value {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    align-self: stretch;
                    width: 160px;

                    span {

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
}