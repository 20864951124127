@import "../../../_colors";

*:has(> .localpantalla) {
    position: relative;

    .localpantalla {
        border: 1px solid @mis_background;
        background-color: @mis_background;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        z-index: 1000;
    }
}