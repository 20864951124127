@import "../../_colors.less";
@import "../../_dims.less";

.challenge {
    .points {
        color: @points-color;
        font-weight: 600 !important;
    }

    .section-2 {
        max-width: 720px;
        margin: 24px auto
    }

    .level {
        .ant-collapse-header {
            cursor: pointer !important;

            .header-unit .header-unit-body {

                .header-unit-body-title {
                    color: @font-color;
                }

                .value {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    align-self: stretch;
                    width: 160px;

                    span {

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
}