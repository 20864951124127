@import "../../../_colors.less";

@keySize: 40px;
@fontSize: 15px;

.numpad {
    margin: 0 auto;
    display: inline-block;
    // border: 1px solid red;

    .number-inbox{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        border: 1px solid @base-color;
        font-size: @fontSize + 8;
        line-height: @fontSize + 8;
        font-weight: bold;
        height: 50px;
        text-align: center;
        color: @font-color;
        margin: 0 5px 5px 0;
        background-color: @neutral;
    }

    .keyboard {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            float: left;
            margin: 0 5px 5px 0;
            height: @keySize;
            font-size: @fontSize;
            width: @keySize;
            line-height: @keySize;
            text-align: center;
            background: #fff;
            border: 1px solid @base-color;
            border-radius: 5px;
        }

        .capslock,
        .tab,
        .left-shift,
        .clearl,
        .switch {
            clear: left;
        }

        .tab,
        .delete {
            width: @keySize;
        }

        .capslock {
            width: 80px;
        }

        .return {
            width: 90px;
        }

        .left-shift {
            width: 70px;
        }

        .switch {
            width: 90px;
        }

        .rightright-shift {
            width: 109px;
        }

        .lastitem {
            margin-right: 0;
        }

        .uppercase {
            text-transform: uppercase;
        }

        .space {
            float: left;
            width: 556px;
        }

        .selector-list(".switch", ".space", ".return") {
            font-size: 16px;
        }
        
        li:hover {
            position: relative;
            top: 1px;
            left: 1px;
            border-color: #e5e5e5;
            cursor: pointer;
        }
    }
}