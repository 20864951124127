@import '../../../../_colors.less';

.numberriddle {
    .transparent {
        color: @transparent;
    }

    .number {
        margin: 24px 0;
        font-weight: bolder;

        .the-number {
            font-size: 1.2em !important;
            padding: 24px 0;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                width: 100%;

                li {
                    margin: 8px 0;

                    span {
                        font-size: 1.2em;
                        margin: 0 8px;

                        &.op {
                            font-size: 1.5em;
                        }

                        &.equals {
                            width: 55px;
                            display: inline-block;
                            text-align: left;
                        }

                        img {
                            width: 32px;
                        }
                    }
                }
            }

            .guess {
                color: @challenge_color;
                font-size: 1.5em;
                font-weight: 900;
                line-height: 36px;
                margin: 16px auto;
                max-width: 80vw;

                span {
                    margin: 0 4px 0 16px;

                    &.tip {
                        margin: 0;
                        font-size: 0.5em;
                        font-weight: 600;
                    }
                }

                img {
                    width: 36px;
                }
            }
        }


    }
}