@import "../../_colors.less";
@import "../../_dims.less";

.learn {
  height: 100vh;

  .level {
    max-width: @MAX-WIDTH;
    margin: 0 auto 32px;
    // border: 1px solid red;

    /* margin-bottom: 10vh; */
    h5 {
      font-weight: 700;
      font-size: 1.1em;
      color: @base-color;
      text-align: center;
      margin: 1em 0;
    }

    .level-separator {
      display: flex;
      text-align: center;
      justify-content: center;
      margin-bottom: 32px;

      span {
        min-width: 33%;
        height: 25px;
        line-height: 40px;
        color: white;
        border-bottom: 2px solid @neutral;
      }

      span:nth-child(2) {
        width: 150px;
        font-weight: 700;
        font-size: 1.1em;
        background: url("./imgs/star.png") no-repeat center;
        background-size: 40px;
        border: none;
        height: 40px;
        line-height: 40px;
      }
    }

    .level-separator.active {
      span {
        border-color: @secondary-color;
      }

      span:nth-child(2) {
        background: url("./imgs/star_a.png") no-repeat center;
        background-size: 40px;
      }
    }

    .ant-progress-bg {
      background-color: @valid;
    }

    .warning-level {
      max-width: 90%;
      border: 1px solid @warning;
      margin: 0 auto 5vh;
      padding: 10px;
      text-align: center;

      .title {
        color: @primary-dark;
        font-size: 1.2em;
        margin: 0.5em 0;

        span {
          font-size: 1.5em;
          color: @warning;
        }
      }

      button {
        color: @primary-color;
        max-width: 80%;
      }
    }

    span.anticon.anticon-right.ant-collapse-arrow {
      display: none !important;
    }

  }

  .grayscale {
    filter: grayscale(70%);
    -webkit-filter: grayscale(70%);
    -moz-filter: grayscale(70%);
    -ms-filter: grayscale(70%);
    -o-filter: grayscale(70%);
    /* filter: url("/static/grayscale.svg#greyscale"); */
    filter: gray;
    -webkit-filter: grayscale(0.7);
  }

  .ant-collapse-borderless {
    background-color: transparent;
  }

  .ant-collapse-item {
    border: 1px solid #ebedf0;
    margin: 10px 10px 15px;
    border-radius: 50px 20px 20px 50px !important;
    box-shadow: 3px 3px 5px 1px #ebedf0;
    background-color: white;
  }

  .ant-collapse-item {
    cursor: pointer;

    .ant-collapse-header {
      padding: 0 !important;
      // width: 100% !important;

      .ant-collapse-header-text {
        width: 100%;
      }

      >i {
        display: none !important;
      }

      img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        /* border-radius: 45px;
      border: 5px solid @active; */
      }

      img.passed {
        /* border: 5px solid @valid !important; */
      }

      img.grayscale {
        /* border: 5px solid @inactive !important; */
      }

      img.test {
        border: none;
      }

      .header-unit {
        display: flex;
        align-items: center;

        .header-unit-body {
          padding: 0 1em;
          margin-top: 10px;
          text-align: left;

          span {
            display: block;
          }

          span:first-child {
            font-size: 1.1em;
            font-weight: 900;
          }
        }
      }

      .header-test {
        display: flex;

        &.active {
          color: @primary-dark;
          cursor: pointer !important;
        }

        .header-test-body {
          padding: 0 1em;
          margin-top: 10px;
          align-items: center;
          display: flex;

          span {
            display: block;
          }

          span:first-child {
            font-weight: 700;
          }
        }
      }
    }

    &.collapse-item-disabled {
      cursor: not-allowed;
    }
  }

  .exerlist {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      padding: 10px;
      line-height: 1em;
      vertical-align: top;
      cursor: pointer;

      span {
        text-align: center;
        word-break: break-all;
        margin-left: 5px;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }

    li._passed {
      color: @validback;
    }
  }

}

@progress-div-height: 5vh;

.exercise-modal {
  height: 100vh !important;
  max-height: 100vh !important;
  overflow: hidden;
  background-color: white;
  max-width: 100% !important;

  .ant-modal-content {
    height: 100%;

    .ant-modal-close {
      align-content: center;
      height: @progress-div-height;
      margin-top: 12px;

      .ant-modal-close-x {
        font-size: 1.5em;
        border-radius: 50%;
        background: white;
        opacity: 0.5;
      }
    }

    .exercise {
      text-align: center;
      background-color: white;

      .help-icon {
        font-size: 1.2em;
        color: @active2;
        position: relative;
        top: -4px;
      }

      .exercise-timer {
        margin-top: 5vh;
      }

      .progress-bar {
        height: @progress-div-height;
        align-content: center;

        .ant-progress {
          top: -7px;
          position: relative;

          .ant-progress-bg {
            background-color: @valid;
          }

          &.ant-progress-status-exception {
            .ant-progress-bg {
              background-color: @error;
            }
          }

        }


      }

      .exercise-body {

        // height: 66vh;

        /* border: 1px solid blue; */
        justify-content: center;
        align-self: center;
        align-items: center;
        display: flex;
        max-width: @MAX-WIDTH;
        margin: 0 auto;
        min-height: 60vh;

        .number {

          .the-number {
            font-size: 1.5em;
            font-weight: 700;
            width: 100%;
            margin: 1em auto 10px;

            &.transparent {
              color: @transparent !important;
            }
          }
        }
      }


      .botones {
        /* border: 1px solid red; */
        /* height: 20vh; */
        font-weight: 700;
        font-size: 1.5em;
        width: 100%;
        max-width: @MAX-WIDTH;
        margin: 0 auto;
        bottom: 0vh;
        left: 0;
        padding: 0 1em;
        position: relative;
        display: flex;
        justify-content: center;
        align-self: center;
        align-items: center;
        flex-direction: row;
        z-index: 1001;

        button {
          color: white;
          border-color: @active;

          &:disabled {
            border: 2px solid @inactive !important;
            background-color: white;
            color: @inactive;
          }
        }
      }

      .botones.error {
        color: @error;

        button {
          color: white;
          background-color: @error;
          border-color: @error;
        }
      }

      .botones.valid {
        color: @valid;

        button {
          color: white;
          background-color: @valid;
          border-color: @valid;
        }
      }
    }
  }

  @media only screen and (orientation: landscape) and (max-height: @MIN-WIN-HEIGHT) {
    .ant-modal-close>span {
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10000;
    }

    .progress {
      display: none;
    }

    .abacus {

      margin-top: -20px;

      margin-bottom: 0;
      padding: 0;

      #showabacus {
        margin-bottom: 0;
        padding: 0;

        table {
          margin-bottom: 0;
          padding: 0;

          tfoot {
            margin-bottom: 0;
            padding: 0;
          }
        }

      }
    }

    .number {
      display: block;
      width: 100vw;

      .the-number {
        position: fixed;
        bottom: 0;
      }

    }

    .botonera {
      position: fixed;
      z-index: 1000;
      bottom: 4px;
      right: 30px;

      .botones {
        padding: 0 !important;
        top: 10px;
        width: 90vw !important;
        background: transparent;

        .message {
          line-height: 1em;
          max-width: 100%;
          background-color: @mis_background;
        }

        .rate-button {
          max-width: 30%;
          margin-left: 64px;
          button:disabled{
            display: none;
          }
        }

        .next-button {
          max-width: 30%;
          margin-left: 64px;
        }
      }
    }

  }
}