@import "../../../_colors.less";

.user-ranking {

    max-width: 720px;
    margin: 0 auto;

    .user-points {
        border-radius: 24px;
        background: @user-back;
        margin: 0 20px 24px;
        display: flex;
        padding: 24px;
        color: @font-color;

        @user-avatar-size: 88;

        .avatar {
            width: @user-avatar-size*1px;
            height: @user-avatar-size*1px;
            border-radius: (@user-avatar-size/2)*1px;
            margin-right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('./imgs/origami.png') center no-repeat @avatar-back;
            background-size: 40px 40px;

            img {
                width: 100%;
                border-radius: (@user-avatar-size/2)*1px;
            }
        }

        .user-data {
            text-align: left;

            h5 {
                font-weight: 900;
                font-size: 1em;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                h6 {
                    font-size: 1em;
                    display: inline;
                    font-weight: 700;
                }


            }
        }
    }

    @top-avatar-size: 80;
    @top-avatar-size-8: 72;

    .top-ten-ranking {
        color: @font-color;
        justify-content: center;
        align-items: flex-end;
        gap: 98px;
        flex-shrink: 0;
        border-radius: 24px;
        margin: 0 20px 24px;
        padding: 24px 16px;
        background-color: @top-ten-back;

        .ranking-header {
            color: @points-color;
            font-weight: 700;
            margin-bottom: 24px;
        }

        .top3 {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            align-self: stretch;
            margin-bottom: 24px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li{
                    margin: auto;    
                }

                li.avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 900;
                    background: url('./imgs/origami.png') center no-repeat @avatar-back;
                    background-size: 40px 40px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }

            .second-p {
                li.avatar {
                    width: (@top-avatar-size-8)*1px;
                    height: (@top-avatar-size-8)*1px;
                    border-radius: (@top-avatar-size-8/2)*1px;
                    border: 4px solid #ECECEC;
                }
            }

            .first-p {
                li.avatar {
                    width: @top-avatar-size*1px;
                    height: @top-avatar-size*1px;
                    border-radius: (@top-avatar-size/2)*1px;
                    border: 4px solid #FBC511;
                }
            }

            .third-p {
                li.avatar {
                    width: 72px;
                    height: 72px;
                    border-radius: 36px;
                    border: 4px solid #D8774E;
                }
            }
        }

        .top-4-10 {
            ul {
                text-align: left;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    align-self: stretch;
                    margin-bottom: 8px;


                    span:first-child {
                        font-weight: 700;
                        text-align: right;
                    }

                    span:nth-child(2) {
                        font-weight: 600;
                        text-align: left;
                        display: flex;
                        align-items: flex-end;
                        gap: 8px;
                        flex: 1 0 0;
                        margin-left: 16px;

                        img {
                            width: 24px;
                            height: 24px;
                            border-radius: 12px;
                        }
                    }

                    span:nth-child(3) {
                        font-weight: 600;
                        min-width: 90px;
                    }

                }

            }
        }

    }
}