@import "../../_colors.less";

.welcome {
    h4 {
        font-weight: 900;
        font-size: 1.2em;
    }

    .welcome-language {
        padding: 12vh 10vh;

        .buttons {
            margin-bottom: 4vh;
        }


    }



    .register {
        padding: 2em;

        form>h3 {
            padding-top: 30px;
            padding-bottom: 10px;
        }

        .rules-modal {
            background-color: white;
        }
    }

    .register2 h3 {
        color: @active3;
        justify-content: flex-start;
    }

    .register2 p {
        justify-content: center;
        padding: 10px;
    }

    .article-click {
        justify-content: space-around !important;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .click1 {
        border: solid 3px @active2;
        border-radius: 50%;
        color: @active2;
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: bold;
        margin: 13px;

        display: inline-flex;
        justify-content: center;
        justify-self: center;
        align-items: center;
    }

    .click2 {
        border: solid 3px @active2;
        border-radius: 100%;
        color: @active2;
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: bold;
        margin: 13px;
        display: inline-flex;
        justify-content: center;
        justify-self: center;
        align-items: center;
    }

    .click-number {
        border: solid 3px @active3;
        border-radius: 50%;
        color: @active3;
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: bold;
        margin: 13px;
        display: inline-flex;
        justify-content: center;
        justify-self: center;
        align-items: center;
        position: relative;
        top: -3px;
    }

    .pais {
        .ant-select-selection {
            border: none;
            border-bottom: 1px solid;
        }
    }

    .TOS {
        text-align: left;
    }

    .register-boy {
        .jugador1 {
            color: @secondary-color;
            font-weight: 700;
            flex: 1em;
            display: flex;
            align-items: center;

            span {
                width: 33%;

                hr {
                    border: 1px solid;
                }
            }
        }

        .apodo {
            margin-bottom: 10px;
            margin-top: 20px !important;
        }

        .edad {
            margin-top: 5px;
            margin-bottom: 40px;
            font-size: 0.9em;
        }
    }
}

.almostthere {
    background-color: @active2;
    height: 100vh;
    color: white;
    align-content: center;

    .body {
        h2 {
            color: white;
        }
        img{
            max-width: 70vw;
        }
    }
}