@import "../../_colors.less";
@import "../../_dims.less";

.welcome {
  padding-top: 0 !important;
  max-width: @MAX-WIDTH;
  margin: 0 auto;
  background: @mis_background !important;

  .WellMessage {
    text-align: center;
    padding: 0;
    color: white;
    background: white;
    background: white;
    background-size: contain;
    background-position: center center;
    height: 100vh;

    h2 {
      font-size: 1.5em;
    }

    .message>p {
      margin: 20px;
      font-weight: 700;
      color: @base-color;
      font-size: 1.2em;
    }

    .wellcome-buttons {
      padding: 3vh;
    }

    .overimg {
      max-height: 65vh;
      display: inline-block;
      
      .misorobanlogo {
        padding-bottom: 7vh;
        width: 100%;
        margin: 0 auto;
      }
    }

    .login {
      padding: 0.7em 0 2em;
      width: 100%;
      background-color: white;
      border-radius: 31.5px;
      border-color: @active2;
      font-weight: bold;
      font-size: 1em;
      color: @active2;
      margin: 0.5em 0;
    }
  }


}