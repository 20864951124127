@import "../../../_colors.less";

section.dragshapesinorder {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-between;

    .dragobjects {
        width: auto;
        display: inline-block;
        margin: auto;

        .drag_things_to_boxes {
            .box {
                border: 3px solid #e7e7e7;
                border-radius: 1em;
                height: 80px;
                width: 230px;
                margin: 10px 0;
                padding: 5px;
                position: relative;
                padding-top: 25px;
                background-color: @icon;


                .box_item_component {
                    width: auto;
                    display: inline-block;
                }

                .box_item_component .outer {
                    border-top: none;
                    min-width: 40px;
                    max-width: 100px;
                    overflow: hidden;
                }

                .box_item_component .item {
                    color: white;
                    margin: 3px;
                }

                .box_item_component .ddcontainerghost {
                    border: 3px dashed #e7e7e7;
                    border-radius: 60%;
                    background-color: #8ae95b;
                    text-align: center;
                }

                .box_item_component .ddcontainerghost .outer {
                    margin: auto;
                }

                .box_item_component img {
                    width: auto;
                }
            }

            .boxes.coins .box {
                height: 190px;
            }

            .highlighted .box {
                box-shadow: inset 0 0 4px #1890ff;
            }

            .inter-boxes {
                position: absolute;
                z-index: 100;
                margin-top: -30px;
                margin-left: 20%;
                height: 60px;


                img {
                    height: 100%;
                }
            }

        }
    }
}
