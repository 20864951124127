@import "~antd/dist/antd.css";
@font-face {
  font-family: "Nunito";
  src: url(fonts/Nunito-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Nunito";
  src: url(fonts/Nunito-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Nunito";
  src: url(fonts/Nunito-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Nunito";
  src: url(fonts/Nunito-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}
html {
  font-size: 100%;
  overflow: auto;
  height: 100%;
  /* h1{} */
}
html body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 16px;
  font-family: "Nunito";
  color: #b8c1cb;
  overflow: auto;
  height: 100%;
}
@supports (-webkit-touch-callout: none) {
  html body {
    margin-top: 25px !important;
  }
}
html hr {
  border: 1px solid #e8e8e8;
  margin: 1em 0;
}
html h2 {
  font-size: 1.5em;
  font-weight: 800;
}
html h3 {
  font-weight: 650;
  line-height: 2em;
  margin: 0 0 0;
  padding: 0;
  font-size: 1.2em !important;
}
html h4 {
  font-weight: 600;
}
html input {
  font-family: "Nunito";
  border-bottom: solid 1.2px rgba(184, 193, 203, 0.33) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-top: 50px !important;
  margin-right: 10px !important;
  font-size: 1.2em !important;
  padding-left: 5px !important;
  font-weight: bold;
  color: #4e4d4d;
  box-shadow: none !important;
  background-color: transparent !important;
}
html .ant-input:placeholder-shown {
  color: #b8c1cb;
}
html .country-h3 {
  color: #b8c1cb;
  font-size: 1em !important;
  text-align: left;
  margin-left: 2px;
  padding-bottom: 0 !important;
  margin-top: 40px;
  margin-bottom: 0 !important;
}
html .age-h3 {
  color: #b8c1cb;
  font-size: 1em !important;
  text-align: left;
  margin-left: 2px;
  padding-bottom: 0 !important;
  margin-top: 10px;
  margin-bottom: 0 !important;
}
html .TOS {
  text-align: flex;
  color: rgba(43, 40, 40, 0.849);
  margin-top: 20px;
  font-size: 0.8em;
  font-weight: bold;
}
html .TOS .tos-name {
  color: #1890ff;
}
html .TOS .ant-checkbox + span {
  padding-left: 8px;
}
html .ant-collapse {
  font-size: 100%;
}
html .ant-btn {
  padding: 0.55em 0 2em;
  width: 100%;
  background-color: #1890ff;
  border-radius: 31.5px;
  border-color: #1890ff;
  font-weight: bold;
  font-size: 1em;
  color: white;
  margin: 0.5em 0;
}
html .ant-btn:disabled {
  background-color: #D0E8FC;
  color: white;
  border: #D0E8FC !important;
}
html .button-blue {
  background-color: #1890ff;
  color: white;
}
html .button-white {
  background-color: white;
  color: #1890ff;
}
html .ant-modal-wrap {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
html .ant-modal {
  background-color: transparent;
}
@supports (-webkit-touch-callout: none) {
  html .ant-modal {
    margin-top: 15px !important;
  }
  html .ant-modal .ant-modal-close {
    margin-top: 10px;
  }
}
html .ant-modal .ant-modal-body {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
}
html .ant-modal .ant-modal-body ol {
  text-align: left;
}
@media only screen and (orientation: landscape) {
  html .ant-modal .ant-modal-body {
    padding: 24px 0;
  }
}
html .ant-modal .ant-modal-footer .ant-btn {
  width: auto;
  padding: 0 2em;
}
html .ant-modal.modal-warning {
  background-color: transparent;
}
html .ant-modal.modal-warning .exclamation-circle {
  font-size: 1.5em;
}
html .ant-modal.modal-warning .ant-modal-confirm-title {
  font-size: 1.5em;
}
html .ant-modal.modal-warning .ant-modal-confirm-content {
  font-size: 1.2em;
}
html .ant-modal.modal-warning button {
  min-width: 100px;
}
html .ant-modal.confirm {
  background-color: transparent;
}
html .ant-modal.confirm .ant-modal-body {
  height: auto;
  padding: 24px;
}
html .MiSoroban {
  background-color: #FAFAFA;
  text-align: center;
  height: 100%;
  margin-bottom: 0px;
  min-height: 100vh;
}
html .MiSoroban .spinner {
  position: absolute;
  z-index: 10000;
  width: 100%;
  text-align: center;
  left: 0;
}
html .MiSoroban .hidden {
  visibility: hidden;
}
html .topLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3vh;
  padding-bottom: 1em;
  height: 7vh;
}
html .topLine .titulo {
  font-weight: 900;
}
html .topLine .saltar {
  margin-right: 2vh;
}
html .topLine .saltar a {
  color: #151515;
}
html .topLine .centerer {
  visibility: hidden;
  margin-left: 2vh;
}
html .topLine .back {
  margin-left: 2vh;
}
html .apple-padding {
  width: 100%;
  height: 4vh;
}
html .ant-notification {
  margin-top: 2em;
}
html .list {
  overflow: scroll;
  height: 75vh;
  margin-bottom: 0px;
}
html .borderedElement {
  margin-left: 2vh;
  margin-right: 2vh;
  border: solid #e8e8e8 1px;
  border-radius: 10px;
  -webkit-box-shadow: 8px 10px 8px -7px rgba(33, 22, 98, 0.2);
  -moz-box-shadow: 8px 10px 8px -7px rgba(33, 22, 98, 0.2);
  box-shadow: 8px 10px 8px -7px rgba(33, 22, 98, 0.2);
  margin-bottom: 3vh;
}
html .share .share-button {
  position: fixed;
  bottom: 71.5px;
  left: 0;
  z-index: 1;
  background-color: #1890ff;
  border: none;
  font-size: 1.2em;
  border-radius: 0;
  height: 2.75em;
  width: 2.75em;
  padding: 0;
}
html .share .share-button .share-icon {
  color: white;
  height: 1.5em;
}
html .ant-avatar > img {
  object-fit: contain;
}
html .menu {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 100%;
  border-top: solid rgba(184, 193, 203, 0.33) 3px !important;
  padding-bottom: 1.25em;
  z-index: 999;
  padding-top: 0.5em;
}
html .menu .ant-menu-item {
  padding: 0 12px !important;
}
@media screen and (min-width: 720px) {
  html .menu {
    max-width: 720px;
    margin-left: -360px;
    left: 50%;
  }
}
@media screen and (max-height: 380px) {
  html .menu {
    padding-top: 0em;
    padding-bottom: 0.5em;
  }
}
html .menu .ant-menu-item-selected {
  border: none !important;
}
html .menu .ant-avatar {
  padding: 0;
}
html .menu .ant-menu-item-active {
  border: none !important;
}
html .menu .ant-menu-submenu {
  border-bottom: none !important;
}
html .menu .ant-menu-item {
  border-bottom: none !important;
  flex: 1 1 0;
  text-align: center;
  margin: 0 1em;
}
html .menu .ant-menu-item a {
  display: inline-block;
  width: 100%;
}
html .crisp-client .cc-1brb6[data-position-reverse=true][data-full-view=true] .cc-1yy0g .cc-1m2mf {
  bottom: 44px !important;
  left: 5px !important;
}
html .ant-select {
  width: 100%;
  text-align: left;
}
html .ant-select input {
  margin-top: 0 !important;
  border-bottom: none !important;
}
html .ant-select-selection--single {
  font-weight: 700;
  color: #b8c1cb;
}
html .spinner {
  z-index: 1000;
  width: 100%;
  margin: 10vh auto;
}
html .ant-popover-content .ant-btn {
  padding: 0.55em 0 2em;
  width: 100px;
  background-color: #1890ff;
  border-radius: 31.5px;
  border-color: #1890ff;
  font-weight: bold;
  font-size: 1em;
  color: white;
  margin: 0.5em 1em;
}
html .ant-divider {
  border-color: rgba(184, 193, 203, 0.33);
}
html .error {
  color: #ff7d7e;
  font-weight: 800;
}
.mis-wrapper {
  background-color: #FAFAFA;
  margin: 0 auto;
  padding-bottom: 100px;
  min-height: 100vh;
  margin-bottom: -100px;
  width: 100vw;
}
.platform-web .MiSoroban {
  background: url('./utils/imgs/back_app_5.jpg') no-repeat fixed center bottom / contain #E6F2E8;
}
@media only screen and (orientation: landscape) {
  .platform-web .MiSoroban .mis-wrapper {
    min-width: 740px;
    width: 60vw;
  }
}
@media only screen and (orientation: portrait) {
  .platform-web .MiSoroban .mis-wrapper {
    width: 100vw;
  }
}
.platform-web.course .MiSoroban {
  background: url('./utils/imgs/back_course_3.jpg') no-repeat fixed center bottom / contain #FFFCE9;
}
