@import '../../../../_colors.less';

.equationnumber {
    .transparent {
        color: @transparent;
    }

    .number {
        .the-number {
            font-size: 1.2em !important;
            .equals{
                font-size: 1.5em;
                color: @active;
            }
            .interrogant{
                color: @errorback;
            }
        }

        margin: 24px 0;
        font-weight: bolder;
    }
}