.audiovisualizer {
    canvas {
        border: 1px solid yellow;
        display: inline-block;
        left: 0;
        top: 0;
        width: 100%;
        height: 120px;
        padding: 16px;
    }
}