section.theory {
    margin-top: 5em;

    @media only screen and (orientation: landscape) {
        margin-top: -10vh;
    }

    .overyt {
        width: 100vw;
        height: 60px;
        background: white;
        opacity: 0.1;
        position: absolute;
    }

    .loveryt {
        width: 100vw;
        height: 60px;
        background: white;
        opacity: 0.1;
        position: absolute;
        bottom: 0px;
    }

    &.text{
        margin-top: 16px;
        font-size: 1.5em;

        p{
            text-align: left;
        }
    }

    &.pdf{
        width: 100vw;
        height: 90vh;
        margin-left: 0;
        margin-right: 0;
        @media only screen and (orientation: portrait) {
            margin-left: -25px;
            margin-top: -20px;
        }
    }
}

.botones.theory {
    @media only screen and (orientation: landscape) {
        margin-top: -16vh !important;
    }
}