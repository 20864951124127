@import "../../../_colors.less";

section.mentalcalculus {
  width: 100%;

  .audio-tutor {
    height: 100px;
    margin-bottom: 24px;

    .wave {
      display: flex;

      span {
        width: 50%;

        img {
          height: 100px;
        }
      }

      span.tutor {
        img {
          border-radius: 50px;
          width: 100px;
        }
      }
    }

  }

  .maf_message {
    img {
      max-width: 50px;
    }
  }

  .closed_eyes{
    height: 50vh;
    margin-top: 15vh;
    img{
      width: 70%;
    }
  }
}