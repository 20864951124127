.tutor-modal{
    .body{
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        h3{
            font-weight: 900;
        }
        h3.wannatrytutor{
            span{
                display: inline-block;
                padding: 5px;
                width: 70%;
                margin-right: 2%;
            }
            img{
                max-width: 28%;
                max-height: 200px;
            }
        }
    }
    .botonera{
        display: flex;
        justify-content: space-between;
        button{
            margin-left: 2px;
            margin-right: 2px;
        }

    }
}