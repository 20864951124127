@import "../../../_colors.less";

@cell-size: 70px;
@img-size: 65px;

.mesh {
    display: table;
    text-align: center;
    margin: 0 auto;
    position: relative;

    .mesh-row {
        display: table-row;
        height: @cell-size;

        .mesh-item {
            border: 1px solid #ccc;
            padding: 5px;
            margin: 5px;
            display: table-cell;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border-radius: 10px;
            min-width: @cell-size;
            line-height: @cell-size;
            color: @base-color;

            >div {
                display: inline-block;

                img {
                    max-width: @img-size;
                }
            }


            &.selected {
                background-color: @base-color;
            }

            &.error {
                background-color: @error;
            }
        }

    }
}