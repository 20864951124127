@import "../../../_colors.less";

.exercise {
    .result {
        font-size: 1.2em;
        margin: 3vh 0;
        /* height: 43vh; */
        /* border: 1px solid blue; */
        justify-content: center;
        align-self: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 1.5em;

        h3 {
            font-size: 2em !important;
            margin-bottom: 3vh;
        }

        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 3vh;

            >li {
                list-style: none;
                margin: 16px 0;

                img {
                    position: relative;
                    top: -3px;
                }
            }
        }

        .level-up{
            height: 48vh;
            img{
                margin-top: 6vh;
                max-height: 24vh;
            }
        }
    }

    .botones.result {
        margin-bottom: 3vh !important;

        button {
            background-color: white !important;
            border-color: @active !important;
            color: @active !important;
        }
    }

    .share-result .social-share{
        margin-top: 3vh;
    }
}