body {
    background-color: #f0f2f5 !important;
}

.ant-layout {
    background-color: transparent !important;
}

.cartoon-record {
    margin: 25vh auto 0;
    text-align: center;

    .ant-progress {
        display: none;
    }

    .cartoonlayout.b10 {
        height: 420px;
    }

    .number.cartoon-specyfic {
        display: none;
    }

    .botones {
        display: none;
    }
}