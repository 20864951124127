@import '../../../_colors.less';

section.cartoonlayout {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 380px;
  margin-bottom: 10px;

  .left {
    display: inline-block;
    border: 1px solid @neutralback;
    background-color: @neutralback;
    padding: 2px;
    height: 100%;
  }

  .left .box {
    height: auto;
    min-width: 100px;
    max-width: 100px;
    min-height: 60vh;
  }

  .left .box .box_item_component {
    margin: 5px;
  }

  .right {
    margin-left: 2px;
    min-width: 200px;
    max-width: 200px;
    border: 1px solid #eacba2;
    background-color: #eacba2;
    display: flex;
    justify-content: space-around;
    padding: 1em 0;
    height: 100%;
  }

  .right .box {
    background: white;
    height: 51px;
    width: 51px;
    border-radius: 50%;
    border: 1px solid @neutralback;
    margin: 5px 2px;
    padding: 2px;
    box-sizing: content-box;
  }

  .right .box.five {
    height: 76px;
    width: 76px;
  }

  .right .box.ten {
    height: 95px;
    width: 95px;
  }

  .ddcontainerghost {
    border: 3px dashed @neutralback;
    border-radius: 60%;
    background-color: #8ae95b;
    text-align: center;
  }

  .ddcontainerghost .outer {
    margin: auto;
  }
}

.number.cartoon-specyfic {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  font-weight: bold;
}