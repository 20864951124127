/***** Bead variables ******/
/* Small */
/***** Varillas & frame variables ******/
/*******/
/* Medium */
/*******/
/*******/
/* Big */
/*******/
/* @bead-height: 58px;
@bead-side-spaces: 50px;

@blue-bead: url("./imgs/azul_b.png");
@red-bead: url("./imgs/rojo_b.png");
@green-bead: url("./imgs/verde_b.png");
@yellow-bead: url("./imgs/amarillo_b.png");
@gray-bead: url("./imgs/gris_b.png");

@upper-bead-first-bottom-space: 107px;
@upper-bead-poner-top-margin: 50px;

@bottom-bead-first-top-space: 104px;
@bottom-bead-poner-top-margin: 50px;
@bottom-bead-lastponer-bottom-margin: 48px;

@varilla-upper-size: 16px 122px;
@varilla-upper-upper-space: 17px;

@varilla-bottom-size: 16px 282px;
@varilla-bottom-bottom-space: 0px; */
/***** Abacus ******/
.abacus {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  position: relative;
  /* upper */
  /* point */
  /* bottom */
}
@media only screen and (orientation: landscape) {
  .abacus {
    overflow: auto;
    max-width: 100vw;
  }
}
.abacus .flexible {
  width: auto;
}
.abacus .hide {
  display: none;
}
.abacus .inactiveabacus {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  height: 100%;
  position: absolute;
  width: 100%;
}
.abacus > #abacuscontroller {
  border: 1px solid #CDCDCD;
  padding: 10px;
}
.abacus #abacusbody {
  padding: 20px 0;
}
.abacus #abacusbody.inpanel {
  padding: 20px 0;
  text-align: center;
}
.abacus #abacusbody .overnumtoexpress {
  width: 100%;
}
.abacus #abacusbody > .overnumtoexpress #numtoexpress.inpanel {
  border: 2px solid #64696F;
  border-radius: 10px;
  font-size: 16pt;
  margin: 10px 0;
  text-align: center;
  width: 60px;
}
.abacus table {
  border: 0px solid #CDCDCD;
  text-align: center;
  border-collapse: collapse;
  margin: 0 auto;
  display: inline-block;
}
.abacus table span {
  display: block;
}
.abacus table .nh {
  background-color: #F6F6F6;
  border: 1px solid #CDCDCD;
  border-radius: 20px;
  color: #CDCDCD;
  font-size: 10pt;
  margin-bottom: 2px;
}
.abacus table tr td {
  display: table-cell;
  padding: 0 2px;
  text-align: center;
  vertical-align: top;
}
.abacus .upper {
  background: url("./imgs/line_up.png") repeat-x scroll 0 top rgba(0, 0, 0, 0);
}
.abacus .upper > td {
  background: url("./imgs/varilla.png") no-repeat scroll center -2px rgba(0, 0, 0, 0);
  background-size: 12px 74px;
  background-position-y: 17px;
  padding-top: 20px;
}
.abacus .upper > td span.first {
  padding-bottom: 68px !important;
}
.abacus .upper > td span.poner {
  margin-top: 34px;
}
.abacus .upper span.bead {
  height: 36px;
  padding: 2px 32px;
  cursor: pointer;
  position: relative;
}
.abacus .upper span.bead1 {
  background: url("./imgs/azul_m.png") no-repeat scroll center top rgba(0, 0, 0, 0);
}
.abacus .upper span.bead2 {
  background: url("./imgs/rojo_m.png") no-repeat scroll center top rgba(0, 0, 0, 0);
}
.abacus .upper span.bead3 {
  background: url("./imgs/verde_m.png") no-repeat scroll center top rgba(0, 0, 0, 0);
}
.abacus .upper span.bead4 {
  background: url("./imgs/amarillo_m.png") no-repeat scroll center top rgba(0, 0, 0, 0);
}
.abacus .upper span.bead5 {
  background: url("./imgs/claro.png") no-repeat scroll center top rgba(0, 0, 0, 0);
}
.abacus .upper span.bead.claro {
  background: url("./imgs/claro.png") no-repeat scroll center top rgba(0, 0, 0, 0);
}
.abacus .upper span.bead.gris {
  background: url("./imgs/gris_m.png") no-repeat scroll center top rgba(0, 0, 0, 0);
}
.abacus .points {
  background: url("./imgs/trav_medio.png") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
}
.abacus .points > td {
  padding: 0;
  text-align: left;
  vertical-align: middle;
  width: 0;
  line-height: 0;
}
.abacus span.point {
  background: url("./imgs/point.png") no-repeat scroll 2px 3px rgba(0, 0, 0, 0);
  margin-left: -15px;
  padding: 9px 0;
}
.abacus span.pointw {
  background: url("./imgs/pointw.png") no-repeat scroll 2px 3px rgba(0, 0, 0, 0);
  margin-left: 0;
  padding: 9px 0;
  width: 2px;
}
.abacus .bottom {
  background: url("./imgs/line_down.png") repeat-x scroll 0 bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td {
  background: url("./imgs/varilla.png") no-repeat scroll center -19px rgba(0, 0, 0, 0);
  background-size: 12px 180px;
  background-position-y: 0px;
  padding-bottom: 20px;
}
.abacus .bottom > td span {
  display: block;
}
.abacus .bottom > td span.first {
  padding-top: 67px !important;
}
.abacus .bottom > td span.ponsup {
  padding-bottom: 67px !important;
}
.abacus .bottom > td span.bead {
  height: 36px;
  padding: 2px;
  cursor: pointer;
  position: relative;
}
.abacus .bottom > td span.bead1 {
  background: url("./imgs/azul_m.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead2 {
  background: url("./imgs/rojo_m.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead3 {
  background: url("./imgs/verde_m.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead4 {
  background: url("./imgs/amarillo_m.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead5 {
  background: url("./imgs/claro.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead.claro {
  background: url("./imgs/claro.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead.gris {
  background: url("./imgs/gris_m.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead.transpa {
  background: url("./imgs/transpa.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead.marron {
  background: url("./imgs/marron.png") no-repeat scroll center bottom rgba(0, 0, 0, 0);
}
.abacus .bottom > td span.bead.last.lastup {
  margin-bottom: 33px;
}
.abacus table thead tr .lm.left,
.abacus table thead tr .lm.right {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
}
.abacus table tfoot tr td {
  color: rgba(0, 0, 0, 0.85);
}
.abacus table tr .lm.left {
  background: url("./imgs/line_left.png") repeat-y scroll center 0 rgba(0, 0, 0, 0);
  padding: 0 8px !important;
}
.abacus table tr .lm.right {
  background: url("./imgs/line_right.png") repeat-y scroll center 0 rgba(0, 0, 0, 0);
  padding: 0 8px !important;
}
.abacus .virtualabacus {
  padding-top: 20px;
  text-align: center;
}
.abacus #resultvalue thead {
  display: none;
}
/* HAND */
.hand {
  position: absolute;
  z-index: 100;
}
.hand.up {
  animation: action_up 2s infinite;
  animation-timing-function: ease;
}
.hand.down {
  animation: action_down 2s infinite;
  animation-timing-function: ease;
}
.hand img {
  width: 96px;
}
