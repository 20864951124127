section.mentalsumsubstraction {
  min-height: 40vh;
  display: flex;
  align-content: center;
  justify-content: center;
}
section.mentalsumsubstraction .number {
  min-width: 100px;
  margin-top: 10vh;
}


