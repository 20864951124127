@import "../../../_dims.less";

.tolandscape {
    display: none;
}
.toportrait {
    display: none;
}

.none {
    display: none;
    visibility: hidden;
}

@media only screen and (orientation: portrait) {
    .tolandscape {
        border: 10px;
        display: block !important;
        background: url(./imgs/phone_rotate_l.png) no-repeat center top;
        background-size: contain;
        height: 100vh;
        width: 100vw;
        z-index: 1002;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        margin-top: 43px;
        // margin-left: -33px;
        padding-top: 60vh;
    }    
}

@media only screen and (orientation: landscape) and (max-height: @MIN-WIN-HEIGHT) {
    .toportrait {
        border: 10px;
        display: block !important;
        background: url(./imgs/phone_rotate_p.png) no-repeat center top;
        background-size: contain;
        height: 100vh;
        width: 100vw;
        z-index: 1002;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        margin-top: 0;
        margin-left: -33px;
        padding-top: 60vh;
    }
}