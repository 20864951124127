@import "../../_colors.less";

@option-width: 80%;
@max-option-width: 600px;

.subscription {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    background: @premium;
    padding: 15px;

    .icon{
        img{
            width: 48px;
        }
    }

    h3 {
        color: white;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .ant-card {
        margin: 0 auto;
        border-radius: 7px;
        padding: 5px;
        margin: 5px auto;
        width: @option-width;
        max-width: @max-option-width;

        .ant-card-head {
            min-height: auto;
            padding: 0;
            font-weight: bold;
            border-bottom: none;

            .ant-card-head-title {
                padding: 0;
            }
        }

        .ant-card-body {
            padding: 0;

            p {
                margin: 0;
                font-size: 0.9em;
            }
        }

        &.selected {
            background-color: @abacuscolor;
            width: @option-width+10;
            max-width: @max-option-width+30;

            .ant-card-head-title {
                font-size: 1.2em;
            }

            .ant-card-body {
                font-size: 1.05em;
            }
        }
    }

    .empezar {
        width: @option-width;
        max-width: @max-option-width;
        font-size: 1.5em;
    }

    .restore {
        width: @option-width;
        background-color: transparent;
        max-width: @max-option-width;
    }

    .data {
        font-size: 0.9em;
        color: white;
    }

    .try {
        color: white;
    }

    .botones .message.error {
        color: @error;
        font-size: 1.2em;
    }
}

.subscribe-button {
    margin: 0 auto;
    margin-bottom: 24px;
    text-align: center;

    .header{
        img{width: 48px;}
    }

    .activar {
        width: @option-width;
        max-width: @max-option-width;
        background-color: @premium;
        border-color: @premium;
        line-height: 24px;
        img {
            width: 24px;
            margin-right: 8px;
            margin-top: -4px;
        }
    }

    .try {
        background-color: white;
        color: @premium;
    }

}

.subscription-modal {
    background: @premium !important;

    .ant-modal-header {
        background: @premium !important;
        border-color: @premium;

        .ant-modal-title {
            color: white;
        }

    }

    .ant-modal-content {
        background: @premium !important;

        .ant-modal-close {
            color: white;
        }

        .ant-modal-body {
            padding-top: 0;
            height: auto !important;
            padding: 0;
        }
    }
}

.ant-modal-wrap:has(.subscription-modal) {
    overflow-y: auto !important;
}

.premium{
    color: @premium;
    display: inline-block;
    margin-left: 8px;
    font-size: 1.2em;
    img{
        width: 24px;
    }
}